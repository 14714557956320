import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ArchivedFilter,
  DossierPrivacyFilter,
  GlobalFindParams,
  Office,
  RoleEnum,
} from '@mychrono/models';

@UntilDestroy()
@Component({
  selector: 'nca-list-header',
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListHeaderComponent implements OnInit {
  @Input() allowSearch = true;
  @Input() allowFilterByRole = false;
  @Input() allowFilterByArchived = true;
  @Input() allowFilterByOffice = false;
  @Input() allowFilterShowPrivateDossier = false;
  @Input() hasCustomFilters = true; // used for spacings only
  @Input() defaultArchivedFilter!: ArchivedFilter;
  @Input() defaultShowPrivateDossier?: DossierPrivacyFilter;
  @Input() searchPlaceholder?: string;
  @Input() listParams!: GlobalFindParams;
  @Input() itemWordPlural = 'éléments';
  @Input() itemWordSingular = 'élément';
  @Input() offices!: Office[];
  @Output() updateParams = new EventEmitter<Partial<GlobalFindParams>>();

  labelByRole: Record<RoleEnum | any, string> = {
    [RoleEnum.Visitor]: 'Visiteurs',
    [RoleEnum.Collaborator]: 'Collaborateurs',
    [RoleEnum.Admin]: 'Administrateurs',
  };

  roleMap = [RoleEnum.Visitor, RoleEnum.Collaborator, RoleEnum.Admin].map(
    (role) => ({
      key: role,
      value: this.labelByRole[role],
    })
  );

  optionsForm!: FormGroup;

  constructor(private readonly fb: FormBuilder) {}

  ngOnInit(): void {
    this.optionsForm = this.fb.group({
      ...(this.allowSearch && {
        searchValue: [this.listParams.searchValue ?? undefined],
      }),
      ...(this.allowFilterByRole && { roles: [this.listParams.roles ?? []] }),
      ...(this.allowFilterByOffice && {
        officeId: [this.listParams.officeId ?? undefined],
      }),
      ...(this.allowFilterByArchived && {
        archived: [this.listParams.archived ?? this.defaultArchivedFilter],
      }),
      ...(this.allowFilterShowPrivateDossier && {
        showPrivateDossier: [
          this.listParams.showPrivateDossier ?? this.defaultShowPrivateDossier,
        ],
      }),
    });

    this.optionsForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.updateParams.emit({ ...value, skip: 0 });
      });
  }

  resetFilters(): void {
    this.optionsForm.patchValue({
      ...(this.allowSearch && { searchValue: undefined }),
      ...(this.allowFilterByRole && { roles: [] }),
      ...(this.allowFilterByOffice && { officeId: undefined }),
      ...(this.allowFilterByArchived && {
        archived: this.defaultArchivedFilter,
      }),
      ...(this.allowFilterShowPrivateDossier && {
        showPrivateDossier: this.defaultShowPrivateDossier,
      }),
    });
  }

  get activeFiltersCount(): number {
    let count = 0;
    if (this.allowFilterByRole && (this.rolesControl.value as []).length > 0) {
      count++;
    }
    if (
      this.allowFilterByArchived &&
      this.archivedControl.value !== this.defaultArchivedFilter
    ) {
      count++;
    }
    if (this.allowFilterByOffice && !!this.officeIdControl.value) {
      count++;
    }
    if (
      this.allowFilterShowPrivateDossier &&
      this.showPrivateDossierControl.value !== this.defaultShowPrivateDossier
    ) {
      count++;
    }
    return count;
  }

  get searchControl(): FormControl {
    return this.optionsForm.get('searchValue') as FormControl;
  }

  get archivedControl(): FormControl {
    return this.optionsForm.get('archived') as FormControl;
  }

  get officeIdControl(): FormControl {
    return this.optionsForm.get('officeId') as FormControl;
  }

  get showPrivateDossierControl(): FormControl {
    return this.optionsForm.get('showPrivateDossier') as FormControl;
  }

  get rolesControl(): FormControl {
    return this.optionsForm.get('roles') as FormControl;
  }
}
