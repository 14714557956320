<div class="card">
  <div class="card__head" *ngIf="showHead">
    <h2 class="card__head__title">
      <ng-content select="[title]"></ng-content>
    </h2>
    <div class="card__head__actions" *ngIf="showActions">
      <ng-content select="[actions]"></ng-content>
    </div>
  </div>

  <ng-content select="[content]"></ng-content>

  <nav class="card__nav">
    <ul class="links">
      <li class="links__item" *ngFor="let nav of navItems; trackBy: trackByNav">
        <a
          class="links__item__link"
          routerLinkActive="links__item__link--active"
          queryParamsHandling="preserve"
          [routerLink]="nav.route"
        >
          {{ nav.label }}
        </a>
      </li>
    </ul>
  </nav>
</div>
