import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavMenuItem } from '../nav-menu/nav-menu.interface';

@Component({
  selector: 'nca-view-header',
  templateUrl: './view-header.component.html',
  styleUrls: ['./view-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewHeaderComponent {
  @Input() navItems!: NavMenuItem[];
  @Input() showActions = true;
  @Input() showHead = true;

  trackByNav(_: number, nav: NavMenuItem): string {
    return nav.label;
  }
}
