<header class="head">
  <nca-search-input
    class="head__search"
    *ngIf="allowSearch"
    [placeholder]="searchPlaceholder"
    [formControl]="searchControl"
  ></nca-search-input>

  <button
    [ngbPopover]="filters"
    #filtersTrigger="ngbPopover"
    popoverClass="popover-filter"
    autoClose="outside"
    [placement]="['bottom-end']"
    [class.head__search--custom-cta]="hasCustomFilters"
    class="btn btn-sm btn-light-primary head__search px-3 px-lg-4 ms-auto"
  >
    <span class="head__search__flag" *ngIf="activeFiltersCount > 0">
      {{ activeFiltersCount }}
    </span>
    <fa-icon icon="filters" class="btn-icon me-0 me-lg-2"></fa-icon>
    <span class="d-none d-lg-inline">Filtres</span>
  </button>
  <ng-content select="[action]"></ng-content>
</header>

<ng-template #filters>
  <div class="filters">
    <div class="filters__header">Options de recherche</div>
    <div class="filters__content">
      <div class="filter" *ngIf="allowFilterByRole">
        <nca-select-input
          itemWordSingular="rôle"
          itemWordPlural="rôles"
          [formControl]="rolesControl"
          [items]="roleMap"
          [multiple]="true"
          [clearable]="true"
        ></nca-select-input>
      </div>

      <div class="filter" *ngIf="allowFilterByOffice">
        <nca-select-input
          itemWordSingular="bureau"
          itemWordPlural="bureaux"
          placeholder="Filtrer par bureau"
          [formControl]="officeIdControl"
          [items]="offices"
          [multiple]="false"
          [clearable]="true"
          notFoundText="Aucun bureau disponible"
          bindLabel="name"
          bindValue="officeId"
        ></nca-select-input>
      </div>

      <div class="filter archived" type="button" *ngIf="allowFilterByArchived">
        <p class="filter__title">{{ itemWordPlural }} archivés :</p>

        <div class="archived__group">
          <button
            type="button"
            class="archived__btn btn btn-sm btn-outline btn-outline-primary btn-outline-dashed"
            [class.archived__btn--checked]="archivedControl.value === 'false'"
            (click)="archivedControl.setValue('false'); filtersTrigger.close()"
          >
            Non
          </button>

          <button
            type="button"
            class="archived__btn btn btn-sm btn-outline btn-outline-primary btn-outline-dashed"
            [class.archived__btn--checked]="archivedControl.value === 'true'"
            (click)="archivedControl.setValue('true'); filtersTrigger.close()"
          >
            Oui
          </button>

          <button
            type="button"
            class="archived__btn btn btn-sm btn-outline btn-outline-primary btn-outline-dashed"
            [class.archived__btn--checked]="archivedControl.value === 'all'"
            (click)="archivedControl.setValue('all'); filtersTrigger.close()"
          >
            Tous
          </button>
        </div>
      </div>

      <div class="filter archived" *ngIf="allowFilterShowPrivateDossier">
        <p class="filter__title">{{ itemWordPlural }} privés :</p>

        <div class="archived__group">
          <button
            class="archived__btn btn btn-sm btn-outline btn-outline-primary btn-outline-dashed"
            [class.archived__btn--checked]="
              showPrivateDossierControl.value === 'false'
            "
            (click)="
              showPrivateDossierControl.setValue('false');
              filtersTrigger.close()
            "
          >
            Non
          </button>

          <button
            class="archived__btn btn btn-sm btn-outline btn-outline-primary btn-outline-dashed"
            [class.archived__btn--checked]="
              showPrivateDossierControl.value === 'true'
            "
            (click)="
              showPrivateDossierControl.setValue('true'); filtersTrigger.close()
            "
          >
            Oui
          </button>

          <button
            class="archived__btn btn btn-sm btn-outline btn-outline-primary btn-outline-dashed"
            [class.archived__btn--checked]="
              showPrivateDossierControl.value === 'all'
            "
            (click)="
              showPrivateDossierControl.setValue('all'); filtersTrigger.close()
            "
          >
            Tous
          </button>
        </div>
      </div>
    </div>
    <div class="filters__footer">
      <button
        class="btn btn-sm btn-light"
        (click)="filtersTrigger.close(); resetFilters()"
      >
        Effacer
      </button>
      <button class="btn btn-sm btn-primary" (click)="filtersTrigger.close()">
        Fermer
      </button>
    </div>
  </div>
</ng-template>
