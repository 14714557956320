<div class="foot">
  <p class="foot__count" *ngIf="(listParams.total ?? 0) > 0">
    Résultats de {{ listParams.skip! + 1 }} à
    {{
      listParams.skip! + listParams.limit! > listParams.total!
        ? listParams.total
        : listParams.skip! + listParams.limit!
    }}
    {{ listParams.total! < 2 ? itemWordSingular : itemWordPlural }} &nbsp;({{
      listParams.total!
    }}
    au total)
  </p>

  <ngb-pagination
    *ngIf="listParams.total"
    class="foot__pagination"
    size="sm"
    [(page)]="page"
    [ellipses]="true"
    [maxSize]="4"
    [collectionSize]="listParams.total!"
    [pageSize]="listParams.limit!"
    [directionLinks]="true"
    (pageChange)="updatePagination($event)"
  >
    <ng-template ngbPaginationPrevious>
      <fa-icon icon="chevron-left"></fa-icon>
    </ng-template>
    <ng-template ngbPaginationNext>
      <fa-icon icon="chevron-right"></fa-icon>
    </ng-template>
  </ngb-pagination>
</div>
