<div class="box">
  <button
    *ngIf="!hideClose"
    class="box__close btn btn-sm btn-icon"
    type="button"
    role="button"
    aria-label="Fermer la fenêtre"
    (click)="cancel.emit()"
  >
    <fa-icon [icon]="['fal', 'xmark']"></fa-icon>
  </button>
  <header class="box__header" *ngIf="!hideHeader">
    <ng-content select="[header]"></ng-content>
  </header>
  <div class="box__content">
    <ng-content select="[content]"></ng-content>
  </div>
  <footer class="box__footer" *ngIf="!hideFooter">
    <button
      *ngIf="cancelLabel"
      type="button"
      class="box__footer__cancel btn btn-sm me-4"
      (click)="triggerCancel()"
    >
      {{ cancelLabel }}
    </button>
    <button
      *ngIf="confirmLabel"
      type="button"
      [disabled]="disableConfirm"
      class="btn btn-sm btn-{{ confirmColor }}"
      (click)="triggerConfirm()"
    >
      {{ confirmLabel }}
    </button>
  </footer>
</div>
