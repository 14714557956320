import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'nca-multi-step-form',
  templateUrl: './multi-step-form.component.html',
  styleUrls: ['./multi-step-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiStepFormComponent {
  @Input() steps!: string[];
  @Input() currentStep!: number;
  @Output() goToStep = new EventEmitter<number>();

  trackByStep(_: number, value: string): string {
    return value;
  }
}
