import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'nca-notif-banner',
  templateUrl: './notif-banner.component.html',
  styleUrls: ['./notif-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotifBannerComponent {
  @Input() color: 'success' | 'primary' | 'danger' | 'warning' | 'info' =
    'primary';
  @Input() icon?: IconProp;
  @Input() hasSubtitle = true;
  @Input() hasAction = false;
}
