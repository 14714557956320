import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UserFindParams } from '@mychrono/models';

@Component({
  selector: 'nca-list-footer',
  templateUrl: './list-footer.component.html',
  styleUrls: ['./list-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListFooterComponent implements OnChanges {
  @Input() listParams!: UserFindParams;
  @Input() itemWordPlural = 'éléments';
  @Input() itemWordSingular = 'élément';
  @Output() updateParams = new EventEmitter<Partial<UserFindParams>>();

  page = 1;

  ngOnChanges(changes: SimpleChanges): void {
    const currValue: UserFindParams = changes['listParams'].currentValue;
    if (currValue.skip !== undefined && currValue.limit !== undefined) {
      this.page = Math.trunc(currValue.skip / currValue.limit) + 1;
    }
  }

  updatePagination(event: number) {
    this.updateParams.emit({
      skip: (event - 1) * (this.listParams.limit ?? 20),
    });
  }
}
