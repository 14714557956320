<div class="main">
  <div class="main__stepper">
    <div
      class="tile"
      [class.tile--active]="currentStep === index"
      [class.tile--activable]="currentStep > index"
      *ngFor="let step of steps; trackBy: trackByStep; let index = index"
    >
      <span class="tile__count">
        {{ index + 1 }}
      </span>
      <a class="tile__label">{{ step }}</a>
    </div>
  </div>

  <div class="main__content">
    <ng-content></ng-content>
  </div>
</div>
