import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'nca-basic-spinner',
  templateUrl: './basic-spinner.component.html',
  styleUrls: ['./basic-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicSpinnerComponent {
  @Input() size: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' =
    '2';
}
