export enum EmailTypeEnum {
  OnboardingSignUp = 'onboardingSignup',
  SignUpVerify = 'signUpVerify',
  PasswordReset = 'passwordReset',
  DossierPublished = 'dossierPublished',
  DailyReport = 'dailyReport',
  IncomingTasks = 'incomingTasks',
  DeadlineTasks = 'deadliningTasks',
  EmailMessage = 'emailMessage',
  CommentWritten = 'commentWritten',
  TaskCompleted = 'taskCompleted',
  TaskLate = 'taskLate',
}

export const InternLabelByEmailTypeEnum: Record<EmailTypeEnum, string> = {
  [EmailTypeEnum.OnboardingSignUp]: 'Onboarding nouvel utilisateur',
  [EmailTypeEnum.SignUpVerify]: 'Vérification nouvel utilisateur',
  [EmailTypeEnum.PasswordReset]: 'Réinitialisation de mot de passe',
  [EmailTypeEnum.DossierPublished]: 'Publication de dossier',
  [EmailTypeEnum.DailyReport]: `Rapport d'activité`,
  [EmailTypeEnum.IncomingTasks]: 'Tâches en approche de la date de réalisation',
  [EmailTypeEnum.DeadlineTasks]: 'Tâches en attentes de la date butoir',
  [EmailTypeEnum.EmailMessage]: 'Message par email',
  [EmailTypeEnum.CommentWritten]: 'Commentaire rédigé',
  [EmailTypeEnum.TaskCompleted]: 'Tâche terminée',
  [EmailTypeEnum.TaskLate]: 'Tâche en retard',
};

export interface EmailModel {
  from: { address: string; name: string };
  to: string;
  subject: string;
  html: string;
}
