<div class="notif alert" [ngClass]="'alert-' + color">
  <div class="notif__icon" *ngIf="!!icon">
    <fa-icon [icon]="icon" [ngClass]="'text-' + color"></fa-icon>
  </div>
  <div class="notif__wrap">
    <div class="notif__body">
      <h4 class="notif__body__title" [ngClass]="'text-' + color">
        <ng-content select="[title]"></ng-content>
      </h4>
      <p class="notif__body__subtitle" *ngIf="hasSubtitle">
        <ng-content select="[subtitle]"></ng-content>
      </p>
    </div>
    <div class="notif__actions" *ngIf="hasAction">
      <ng-content select="[action]"></ng-content>
    </div>
  </div>
</div>
