<svg
  class="spinner-svg"
  [ngClass]="{
    'spinner-svg--size-1': size === '1',
    'spinner-svg--size-2': size === '2',
    'spinner-svg--size-3': size === '3',
    'spinner-svg--size-4': size === '4',
    'spinner-svg--size-5': size === '5',
    'spinner-svg--size-6': size === '6',
    'spinner-svg--size-7': size === '7',
    'spinner-svg--size-8': size === '8',
    'spinner-svg--size-9': size === '9',
    'spinner-svg--size-10': size === '10'
  }"
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
>
  <g class="spinner-svg__icon">
    <path
      class="spinner-bg"
      stroke="#f1faff"
      stroke-width="1.5"
      d="M11.929 18.373a6.373 6.373 0 100-12.746 6.373 6.373 0 000 12.746z"
    />
    <path
      class="spinner-tick"
      fill="#009ef7"
      d="M17.065 14.175a.422.422 0 00.185.542l.71.364a.383.383 0 00.525-.178 7.17 7.17 0 00-2.93-9.101.382.382 0 00-.532.163l-.363.71a.422.422 0 00.166.547 5.578 5.578 0 012.239 6.952z"
    />
  </g>
</svg>
