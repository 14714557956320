import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'nca-back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackToTopComponent {
  goToTop(): void {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}
