import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'nca-modal-content-box',
  templateUrl: './modal-content-box.component.html',
  styleUrls: ['./modal-content-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalContentBoxComponent {
  @Input() hideHeader = false;
  @Input() hideFooter = false;
  @Input() hideClose = false;
  @Input() cancelLabel = 'Annuler';
  @Input() disableConfirm = false;
  @Input() confirmLabel: string | null = 'Confirmer';
  @Input() confirmColor: 'primary' | 'danger' | 'success' = 'primary';
  @Output() cancel = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<void>();

  triggerCancel(): void {
    this.cancel.emit();
  }

  triggerConfirm(): void {
    this.confirm.emit();
  }
}
